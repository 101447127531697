<template>
  <div class="auth-wrap">
    <div class="bread-wrap">
      <span class="bread-title">账号管理/个人中心/实名认证</span>
    </div>
    <div class="content">
      <p class="authcheack"><span class="authtitle">选择认证方式</span></p>
      <div class="cheack-wrap">
        <div @click="navienterprise" class="Enterprise">
          <p class="entertitle">
            <i class="iconfont icon-yewuzu1"></i>
            <span class="icontext">企业证件认证</span>
          </p>
          <p class="entercontent">
            <span class="spancontent"
              >1.
              需提供企业营业执照、以及法定代表人身份证、法定代表人手机号；</span
            >
          </p>
          <p class="entercontent">
            <span class="spancontent"
              >2.
              系统通过所提供的执照及法人信息进行人工审核，需0-3个工作日完成企业认证；</span
            >
          </p>
        </div>
        <div class="geren">
          <p class="entertitle">
            <i class="iconfont icon-yewuzu1"></i>
            <span class="icontext">个人证件认证（暂不支持）</span>
          </p>
          <p class="entercontent">
            <span class="spancontent"
              >1. 需提供中国大陆居民身份证、个人手机号等；</span
            >
          </p>
          <p class="entercontent">
            <span class="spancontent">2. 需提供本人证件照片</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navigation from "@/utils/navigation"
export default {
  data() {
    return {};
  },
  methods: {
      navienterprise(){
       navigation.navigateTo({
           url:'enterprise'
       })
      }
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>