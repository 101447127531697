var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-wrap"},[_vm._m(0),_c('div',{staticClass:"content"},[_vm._m(1),_c('div',{staticClass:"cheack-wrap"},[_c('div',{staticClass:"Enterprise",on:{"click":_vm.navienterprise}},[_vm._m(2),_vm._m(3),_vm._m(4)]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bread-wrap"},[_c('span',{staticClass:"bread-title"},[_vm._v("账号管理/个人中心/实名认证")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"authcheack"},[_c('span',{staticClass:"authtitle"},[_vm._v("选择认证方式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"entertitle"},[_c('i',{staticClass:"iconfont icon-yewuzu1"}),_c('span',{staticClass:"icontext"},[_vm._v("企业证件认证")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"entercontent"},[_c('span',{staticClass:"spancontent"},[_vm._v("1. 需提供企业营业执照、以及法定代表人身份证、法定代表人手机号；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"entercontent"},[_c('span',{staticClass:"spancontent"},[_vm._v("2. 系统通过所提供的执照及法人信息进行人工审核，需0-3个工作日完成企业认证；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"geren"},[_c('p',{staticClass:"entertitle"},[_c('i',{staticClass:"iconfont icon-yewuzu1"}),_c('span',{staticClass:"icontext"},[_vm._v("个人证件认证（暂不支持）")])]),_c('p',{staticClass:"entercontent"},[_c('span',{staticClass:"spancontent"},[_vm._v("1. 需提供中国大陆居民身份证、个人手机号等；")])]),_c('p',{staticClass:"entercontent"},[_c('span',{staticClass:"spancontent"},[_vm._v("2. 需提供本人证件照片")])])])
}]

export { render, staticRenderFns }